import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import styles from "./rent.module.scss"
import { TextField } from "@material-ui/core"

const RentTemplate = ({ location, pageContext }) => {
  function formatMoney(
    amount,
    decimalCount = 2,
    decimal = ".",
    thousands = ","
  ) {
    try {
      decimalCount = Math.abs(decimalCount)
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount

      const negativeSign = amount < 0 ? "-" : ""

      let i = parseInt(
        (amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))
      ).toString()
      let j = i.length > 3 ? i.length % 3 : 0

      return (
        negativeSign +
        (j ? i.substr(0, j) + thousands : "") +
        i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
        (decimalCount
          ? decimal +
            Math.abs(amount - i)
              .toFixed(decimalCount)
              .slice(2)
          : "")
      )
    } catch (e) {
      console.error(e)
    }
  }
  const [activePicture, setActivePicture] = useState(0)
  const [showForm, setShowForm] = useState(false)
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  function facebookPixel() {
    window.fbq("track", "submittedRentForm")
  }
  const product = pageContext.product
  let numberOfPictures = product.productMainImage.length
  const handleImageChange = e => {
    let scrollLeft = e.target.scrollLeft
    let width = e.target.scrollWidth
    let newActivePicture = Math.round((scrollLeft / width) * numberOfPictures)
    setActivePicture(newActivePicture)
  }
  const carouselRef = useRef(null)
  const switchPicture = (e, direction) => {
    let move = carouselRef.current.clientWidth
    console.log(
      carouselRef.current.clientWidth,
      carouselRef.current.scrollLeft,
      move
    )
    if (direction === "r") {
      carouselRef.current.scrollLeft += move
    } else {
      carouselRef.current.scrollLeft -= move
    }
  }
  const onSubmit = e => {
    e.preventDefault()
    setLoading(true)
    if (phone.length !== 10) {
      alert("Tu teléfono debe ser a 10 dígitos")
      setLoading(false)
      return
    }
    const ipData = localStorage.getItem("ipData")
    const w = localStorage.getItem("window")
    const initialUrl = localStorage.getItem("initialUrl")
    if (!w) {
      setLoading(false)
      return
    }
    let url = "https://hook.integromat.com/s9q6ox9k7kroxy3kyax6ib542o9k4klw"
    const data = {
      Placement: "PPDC WEB",
      "Nombre Completo": name,
      Email: email,
      Teléfono: phone,
      ipData,
      w,
      initialUrl,
      Comentarios: product.productTitle,
    }
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      mode: "cors",
      body: JSON.stringify(data),
    }).then(res => {
      if (res.ok) {
        facebookPixel()
        setLoading(false)
        setSubmitted(true)
      } else {
        setLoading(false)
      }
    })
  }
  return (
    <>
      <SEO
        title={product.productTitle}
        url={location.pathname}
        description={product.productShortDescription}
        image={product.productMainImage[0].thumbnails.full.url}
      />
      <div className={styles.pageContainer}>
        {showForm && (
          <form className={styles.form} onSubmit={onSubmit}>
            <div className={styles.closeButtonContainer}>
              <button
                type="button"
                className={styles.closeButton}
                onClick={() => setShowForm(false)}
              >
                X
              </button>
            </div>
            {submitted ? (
              <h3>Gracias, hemos recibido tus datos</h3>
            ) : (
              <>
                <TextField
                  onChange={e => setName(e.target.value)}
                  label="Nombre"
                  value={name}
                  fullWidth
                  type="text"
                  variant="filled"
                  autoComplete="given-name"
                  required
                  InputLabelProps={{ classes: { root: styles.label } }}
                  InputProps={{
                    classes: {
                      root: styles.input,
                      underline: styles.inputUnderline,
                    },
                    autoCapitalize: "true",
                  }}
                  margin="dense"
                />
                <TextField
                  onChange={e => setPhone(e.target.value)}
                  label="Teléfono"
                  value={phone}
                  fullWidth
                  type="tel"
                  variant="filled"
                  autoComplete="tel"
                  required
                  InputLabelProps={{ classes: { root: styles.label } }}
                  InputProps={{
                    classes: {
                      root: styles.input,
                      underline: styles.inputUnderline,
                    },
                    autoCapitalize: "true",
                  }}
                  margin="dense"
                />
                <TextField
                  onChange={e => setEmail(e.target.value)}
                  label="Email"
                  value={email}
                  fullWidth
                  required
                  type="email"
                  variant="filled"
                  autoComplete="email"
                  InputLabelProps={{ classes: { root: styles.label } }}
                  InputProps={{
                    classes: {
                      root: styles.input,
                      underline: styles.inputUnderline,
                    },
                    autoCapitalize: "none",
                  }}
                  margin="dense"
                />
                <button
                  type="submit"
                  disabled={loading}
                  onClick={() => setShowForm(true)}
                  className={styles.quoteButton}
                >
                  Enviar datos
                </button>
              </>
            )}
          </form>
        )}
        <h1 className={styles.productTitle}>{product.productTitle}</h1>
        <div
          className={styles.carouselContainer}
          ref={carouselRef}
          onScroll={e => handleImageChange(e)}
        >
          {numberOfPictures > 1 && (
            <div className={styles.desktopCarouselButtonsContainer}>
              <button
                onClick={e => switchPicture(e, "l")}
                className={styles.carouselButton}
              />
              <button
                onClick={e => switchPicture(e, "r")}
                className={styles.carouselButton}
              />
            </div>
          )}
          {product.productMainImage.map((image, idx) => (
            <img
              key={idx}
              className={styles.imageContainer}
              src={image.thumbnails.full.url}
            />
          ))}
        </div>
        <div className={styles.imageContainerIndicator}>
          {product.productMainImage.map((_, idx) => (
            <div
              key={idx}
              draggable={true}
              className={
                activePicture === idx
                  ? styles.activeDotIndicator
                  : styles.dotIndicator
              }
            />
          ))}
        </div>
        <p>
          Renta por día: ${formatMoney(product.productRentalPrice)}
          {product.productNeedsOperator && (
            <>
              <br />
              Requiere operador
            </>
          )}
        </p>
        {!submitted && (
          <button
            onClick={() => setShowForm(true)}
            className={styles.quoteButton}
          >
            Cotizar
          </button>
        )}
        <div className={styles.descriptionContainer}>
          <h2 className={styles.description}>Descripción</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: product.productDescription.childMarkdownRemark.html,
            }}
          />
        </div>
      </div>
    </>
  )
}
// productNeedsOperator
// typeOfOperator
// canProvideOperator
// productOperatorPrice
// productSlug
RentTemplate.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
}

export default RentTemplate
